import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { normalizedPDF } from '../../../core';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { SubjectAccessRequest } from '../../../core/models/class/subject-access-request';
import { UpdateModeTypes } from '../../../core/models/types';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { SubjectAccessRequestService } from '../../../services/subject-access-request.service';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent extends GenericCreateFeature<SubjectAccessRequest> implements OnInit {
  modeInput = UpdateModeTypes.MODE_SUBJECT_ACCESS_REQUEST;
  isFilePDF: boolean = true;
  isFileSizePassed: boolean = false;
  alertOnFileType = 'من فضلك ادخل الملفات من نوع PDF فقط';
  newFile = false;

  constructor(
    protected router: Router,
    protected _toastLuncher: ToastLuncherService,
    private _subjectAccessRequestService: SubjectAccessRequestService,
  ) {
    super(_subjectAccessRequestService.prefix, _toastLuncher, _subjectAccessRequestService, router);
    this.item = new SubjectAccessRequest();
  }

  ngOnInit() {}

  openSelectFileDialog() {
    document.getElementById('input_file').click();
  }

  onFileChange(fileInput) {
    const reader2 = new FileReader();
    reader2.onload = (e) => {
      // Just set the attachment, don't submit
      this.item.attachment = normalizedPDF(reader2.result);
    };

    if (fileInput.target.files[0].type.match('application/pdf') && fileInput.target.files[0].size < 5300000 * 2) {
      this.newFile = true;
      this.isFilePDF = true;
      this.isFileSizePassed = false;
      reader2.readAsDataURL(fileInput.target.files[0]);
    } else if (!fileInput.target.files[0].type.match('application/pdf')) {
      this.isFilePDF = false;
      this.isFileSizePassed = false;
      throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = true;
      throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
    }
  }

  // Override submit method if needed to add custom validation
  submit() {
    // Add any custom validation before submission
    if (this.validateForm()) {
      super.submit();
    }
  }

  private validateForm(): boolean {
    // Add your custom form validation logic here
    if (!this.item.on_behalf_of_full_name) {
      this._toastLuncher.handleError('الرجاء إدخال اسم صاحب المطلب');
      return false;
    }
    // Add more validation as needed
    return true;
  }
}
