import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { AccesInfoComponent } from './acces-info.component';
import { CreateComponent } from './create/create.component';
const routes: Routes = [
  {
    path: '',
    component: AccesInfoComponent,
    children: [
      {
        path: 'liste',
        component: ListeComponent,
      },
      {
        path: 'edit/:id',
        component: EditComponent,
      },
      {
        path: 'create',
        component: CreateComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccesInfoRoutingModule {}
