import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { PlaintsComponent } from './plaints.component';
import { PlaintsRoutingModule } from './plaints-routing.module';
import { EditComponent } from './edit/edit.component';
import { ListeComponent } from './liste/liste.component';
import { HistoriqueModule } from '../../components/historique/historique.module';
import { ComplaintsService } from '../../services/complaints.service';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { CategoryService } from '../../services/category.service';
import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import { ProgressBarModule } from '../../components/progress-bar/progress-bar.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OurCommonModule } from '../../core';
import { CreateComponent } from './create/create.component';

@NgModule({
  declarations: [PlaintsComponent, ListeComponent, EditComponent, CreateComponent],
  imports: [
    CommonModule,
    ThemeModule,
    NgMultiSelectDropDownModule.forRoot(),
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    PlaintsRoutingModule,
    HistoriqueModule,
    AngularOpenlayersModule,
    SpinnerModule,
    NbSpinnerModule,
    ProgressBarModule,
    OurCommonModule,
  ],
  providers: [ComplaintsService, CategoryService],
})
export class PlaintsModule {}
