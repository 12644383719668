import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { ComplaintsService } from '../../../services/complaints.service';
import { NbLayoutScrollService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import { CategoryService } from '../../../services/category.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from '../../../services/report.service';
import { Category } from '../../../core/models/class/category';
import { getItem, getSubCategories, ItemIsOpenForProcessing, setItem, StorageItem } from '../../../core';
import { UpdateTypes } from '../../../core/models/types';
import { GenericListFeatureWithStatus } from '../../../core/models/class/GenericListFeature';
import { Complaint } from '../../../core/models/class/complaint';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { toArabicDate, toArabicStatus } from '../../../core';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
  providers: [DatePipe],
})
export class ListeComponent extends GenericListFeatureWithStatus<Complaint> implements OnInit {
  complaintCategories = getItem(StorageItem.complaint_categories)?.split(',') || [];

  filterClosed: boolean = false;
  filteredData;
  categories: Array<Category> = [];
  isLoadedAfterFilter = 0;
  category = 'الكل';
  status = [];
  statusList = GeneralService.filterLabels;
  startDate: string | boolean = '';
  endDate: string | boolean = '';
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'value',
    textField: 'title',
    selectAllText: 'الكل',
    searchPlaceholderText: 'البحث',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  constructor(
    public router: Router,
    public datePipe: DatePipe,
    protected scroll: NbLayoutScrollService,
    private _complaintsService: ComplaintsService,
    protected _toastLuncher: ToastLuncherService,
    private categoryService: CategoryService,
    private modalService: NgbModal,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(_complaintsService.prefix, scroll, _complaintsService, router, _toastLuncher, activatedRoute);
    this.getCategories();
  }

  ngOnInit() {}

  exportReport() {
    let status: string = '';
    if (this.status.length) status = this.status.map((s) => s.value).join();

    let cat = this.category;
    if (this.category == 'الكل') cat = '';

    if (!this.startDate || this.startDate === true) this.startDate = '';
    if (!this.endDate || this.endDate === true) this.endDate = '';

    this._service.getReport(this.startDate, this.endDate, status, cat);
    this.modalService.dismissAll();
    this.startDate = '';
    this.endDate = '';
  }

  getTableSettings() {
    return {
      mode: 'external',
      pager: { display: false },
      edit: {
        editButtonContent: '<i class="nb-compose"></i>',
        // saveButtonContent: '<i class="nb-checkmark"></i>',
        // cancelButtonContent: '<i class="nb-close"></i>',
      },
      add: {
        addButtonContent: '<i class="nb-plus"></i>',
        createButtonContent: '<i class="nb-checkmark"></i>',
        cancelButtonContent: '<i class="nb-close"></i>',
      },
      delete: {
        deleteButtonContent: '<i class="nb-trash"></i>',
        confirmDelete: true,
      },
      actions: {
        columnTitle: '',
        delete: false,
        add: true,
      },
      noDataMessage: 'لا يوجد تشكيات',
      columns: {
        id: {
          title: '#',
          type: 'number',
        },
        problem: {
          title: 'المشكل',
          type: 'string',
          width: '400px',
        },
        created_by: {
          title: 'الاسم',
          type: 'string',
          width: '150px',
        },
        contact_number: {
          title: 'الهاتف',
          type: 'number',
          width: '100px',
        },
        status: {
          title: 'الحالة',
          type: 'string',
          filter: {
            type: 'list',
            config: {
              selectText: 'الكل',
              list: GeneralService.filterLabels,
            },
          },
          valuePrepareFunction: (status) => {
            return toArabicStatus(status);
          },
        },
        category: {
          title: 'التصنيف',
          type: 'string',
          filter: {
            type: 'list',
            config: {
              selectText: 'الكل',
              list: this.categories?.length
                ? this.categories.map((c) => {
                    return { value: c.name, title: c.name };
                  })
                : [],
            },
          },
        },
        sub_category: {
          title: 'التصنيف الفرعي',
          type: 'string',
          filter: {
            type: 'list',
            config: {
              selectText: 'الكل',
              list: this.categories?.length
                ? getSubCategories(this.categories).map((sub) => {
                    return { value: sub.name, title: sub.name };
                  })
                : [],
            },
          },
        },
        created_at: {
          title: 'تاريخ الايداع',
          type: 'string',
          valuePrepareFunction: (created_at) => {
            return toArabicDate(created_at);
          },
        },
        updates: {
          title: 'تاريخ اخر تحديث',
          type: 'string',
          valuePrepareFunction: (updates) => {
            return toArabicDate(updates[0].date);
          },
        },
        address: {
          title: 'المكان',
          type: 'string',
          width: '200px',
        },
      },
    };
  }

  getCategories() {
    this.categoryService.get().subscribe((data) => {
      this.categories = data;
      this.refreshTableData();
    });
  }

  handleFilterClosedItems(event) {
    setItem(StorageItem.toFilter, this.filterClosed.toString());

    if (this.filterClosed === true) {
      this.filteredData = this.data.filter(ItemIsOpenForProcessing);
      this.load(this.filteredData);
    } else {
      this.load(this.data);
    }
  }

  preSavingList(data: Complaint[]) {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    data.forEach((item) => {
      item.status = item.updates[0].status || UpdateTypes.RECEIVED;
      item.created_at = this.datePipe.transform(item.created_at, 'yyyy-MM-dd');
    });
    return data;
  }

  openModal(modal) {
    this.modalService.open(modal, { size: 'md' });
  }
}
